import React from 'react';
import { CreateGuesser, InputGuesser } from "@api-platform/admin";
import {AccordionFormPanel} from "@react-admin/ra-form-layout";
import {
        ArrayInput,
        DateTimeInput, email,
        ReferenceInput, regex,
        required,
        SelectInput,
        SimpleFormIterator,
        TextInput
} from "react-admin";
import LatLngInput from "../../form/field/LatLngInput";

const validateEmail = email();
const validateZipCode = regex(/^\d{4,5}$/, 'Must be a valid Zip Code');

export const PlaceCreate = props => (
    <CreateGuesser {...props}>
        <AccordionFormPanel label="Details & Contact" fullWidth defaultExpanded>
            <InputGuesser source="name" label="Place name" />
            <ReferenceInput
                source="franchisePartner"
                reference="franchise_partners"
            >
                <SelectInput optionText={record => `${record.companyName} - ${record.firstname} ${record.lastname}`} />
            </ReferenceInput>
            <TextInput source="contact.email" type="email" validate={validateEmail} />
            <TextInput source="contact.phoneNumber" type="text" />
            <TextInput source="contact.mobileNumber" type="text" />
            <TextInput source="contact.faxNumber" type="text" />
        </AccordionFormPanel>
        <AccordionFormPanel label="Address"  fullWidth>
            <LatLngInput source="coordinates" />
            <TextInput source="address.street" />
            <TextInput source="address.postcode" validate={validateZipCode} />
            <TextInput source="address.city" />
            <TextInput source="address.state" />
            <ReferenceInput
                source="address.country"
                reference="countries"
                allowEmpty
            >
                    <SelectInput optionText="title" />
            </ReferenceInput>
        </AccordionFormPanel>
        <AccordionFormPanel label="Opening Hours" fullWidth>
            <TextInput source="notice" label="Notice" multiline={true} />
            <ArrayInput source="openingHours" label="Opening Hours">
                <SimpleFormIterator>
                    <TextInput source="dayOfWeek" validate={required()} />
                    <DateTimeInput source="opens" validate={required()} />
                    <DateTimeInput source="closes" validate={required()} />
                </SimpleFormIterator>
            </ArrayInput>
            </AccordionFormPanel>
    </CreateGuesser>
);
