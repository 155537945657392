import React from 'react';
import { EditGuesser } from "../../form/EditGuesser";
import { InputGuesser } from "@api-platform/admin";
import {
        ArrayInput,
        DateInput,
        email,
        ReferenceInput,
        regex,
        required,
        SelectInput,
        TextInput,
} from 'react-admin';
import { AccordionForm, AccordionFormPanel } from "@react-admin/ra-form-layout";
import { CompactForm, RaBox } from 'ra-compact-ui';

import LatLngInput from '../../form/field/LatLngInput';

const validateEmail = email();
const validateZipCode = regex(/^\d{4,5}$/, 'Must be a valid Zip Code');

export const PlaceEdit = props => (
    <EditGuesser {...props}>
        {/*<InputGuesser source={"name"} />*/}
        {/*<InputGuesser source={"coordinates"} />*/}
        {/*<InputGuesser source={"address"} />*/}
        {/*<InputGuesser source={"contact"} />*/}
        {/*<InputGuesser source={"franchisePartner"} />*/}
            <AccordionForm autoClose fullWidth>
                    <AccordionFormPanel label="Details & Contact" fullWidth>
                            <InputGuesser source="name" label="Place name" />
                            <ReferenceInput
                                source="franchisePartner"
                                reference="franchise_partners"
                            >
                                    <SelectInput optionText={record => `${record.companyName} - ${record.firstname} ${record.lastname}`} />
                            </ReferenceInput>
                            <TextInput source="contact.email" type="email" validate={validateEmail} />
                            <TextInput source="contact.phoneNumber" type="text" />
                            <TextInput source="contact.mobileNumber" type="text" />
                            <TextInput source="contact.faxNumber" type="text" />
                    </AccordionFormPanel>
                    <AccordionFormPanel label="Address"  fullWidth>
                            <LatLngInput source="coordinates" />
                            <TextInput source="address.street" />
                            <TextInput source="address.postcode" validate={validateZipCode} />
                            <TextInput source="address.city" />
                            <TextInput source="address.state" />
                            <ReferenceInput
                                source="address.country"
                                reference="countries"
                                allowEmpty
                            >
                                    <SelectInput optionText="title" />
                            </ReferenceInput>
                    </AccordionFormPanel>
                    <AccordionFormPanel label="Opening Hours" fullWidth>
                            <TextInput source="notice" label="Notice" multiline={true} />
                            <ArrayInput source="openingHours" label="Opening Hours">
                                    {/*<SimpleFormIterator>*/}
                                    {/*        <TextInput source="dayOfWeek" validate={required()} />*/}
                                    {/*        <SelectInput*/}
                                    {/*            source="language"*/}
                                    {/*            choices={languageChoices}*/}
                                    {/*            defaultValue="en"*/}
                                    {/*        />*/}
                                    {/*        <DateInput source="opens" validate={required()} />*/}
                                    {/*        <DateInput source="closes" validate={required()} />*/}
                                    {/*</SimpleFormIterator>*/}
                                    <CompactForm layoutComponents={[RaBox]}>
                                        <RaBox display="flex" justifyContent="space-around">
                                            <TextInput source="dayOfWeek" validate={required()} />
                                            {/*<SelectInput*/}
                                            {/*    source="language"*/}
                                            {/*    choices={languageChoices}*/}
                                            {/*    defaultValue="en"*/}
                                            {/*/>*/}
                                            <DateInput source="opens" validate={required()} />
                                            <DateInput source="closes" validate={required()} />
                                        </RaBox>
                                    </CompactForm>
                            </ArrayInput>
                    </AccordionFormPanel>
            </AccordionForm>
    </EditGuesser>
);